footer {
    padding:($spacer * 1.5) ($spacer * 3) !important;
    display:flex;
    align-items:stretch;
    border-image: linear-gradient(to right, #0EAEDF, #67BC45) 1 0 0 0;
    border-top: 5px solid;

    @include media-breakpoint-down(md) {
        padding:($spacer * 0.5) ($spacer * 1.5) !important;
    }

    @include media-breakpoint-down(sm) {
        padding:($spacer * 0) ($spacer * 1) !important;
    }

    .footer-wrapper {
        position:relative;
        flex-direction: row;
        align-items: center;
        display:flex;
        flex-grow:1;
        align-self:center;

        img {
            @include media-breakpoint-down(sm) {
                width:24px;
            }

        }

        .footer-terms{
            position:relative;
            display:flex;
            flex-direction: row;
            padding: $spacer * 0.8;
            width:100%;
            justify-content: center;

            @include media-breakpoint-down(md) {
                flex-wrap:wrap;
            }

            a {
                padding: $spacer * 0.1 $spacer;
                font-weight: 400;
                font-size: 1em;

                @include media-breakpoint-down(md) {
                    padding:$spacer * 0.5;
                    font-size: 0.9em;
                }

                @include media-breakpoint-down(sm) {
                    font-size: 0.8em;
                }

            }
        }
    }


}
