.cookie-consent {
    background-color: #fff;
    border: 1px solid rgba(0,0,0,.07);
    bottom: 0;
    left: 0;
    margin-bottom: 1rem;
    margin-left: 1rem;
    padding: 1em;
    position: fixed;
    text-align: center;
    width: 100%;
    max-width: 550px;
    z-index: 99;
    display: flex;
    align-items: center;
    @media (max-width: 768px) {
        margin-bottom: 0;
        margin-left: 0;
    }
    .cookie-consent-logo {
        img {
            width: 100%;
            height: auto;
            display: block;
        }
    }
    .cookie-consent-content {
        margin: 5px 0;
        padding: 0 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .cookie-consent-message {
            color: #000;
            font-size: 13px;
        }
        .cookie-consent-btns {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            width: 100%;
            gap: .5rem;
            @media (max-width: 768px) {
                flex-direction: column;
            }
            .cookie-consent-agree {
                background-color: #0eaedf;
                border: 1px solid #0eaedf;
                border-radius: 3px;
                box-shadow: 0 2px 5px rgba(217,142,0,.15);
                color: #fff;
                font-weight: 700;
                padding: .5em 1em;
                font-size: 13px;
                outline: none;
                transition: all .3s ease-in-out;
                cursor: pointer;
                &:hover {
                    background-color: #000;
                    color: #fff;
                } 
            }
            .cookie-consent-read {
                font-size: 13px;
                color: #0eaedf;
                text-decoration: none;
                transition: all .3s ease-in-out;
                &:hover {
                    color: #000;
                    text-decoration: underline;
                }
            }
        }
    }
}