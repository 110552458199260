// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

//
@import "base_typography";

//
@import "general";

// Navbar
@import "navbar";

// Footer
@import "footer";

// Features
@import "features";

// How it works
@import "hiw";

// Testimonials
@import "testimonials";

// Font awesome
@import "font-awesome/fontawesome";
@import "font-awesome/solid";
@import "font-awesome/regular";

// Contact
@import "contact";

// Pricing plans
@import "pricing";

// Terms conditions cookies etc
@import "terms";
@import "sla";

// Register page
@import "register";

// clients
@import "clients";

// ctas
@import "ctas";

// keypoints
@import "keypoints";

// casestudies
@import "casestudies";

// menu locales
@import "menu-locales";

@import "register_region";
@import "shame";
@import "box_card_resources";


@import "vendors_bootstrap";


@import "flated";
@import "./dropdown-menu";

// cookie
@import "cookie";
