$viewport: (
        '' : 0px,
        'sm' : 567px,
        'md' : 768px,
        'lg' : 992px,
        'xl' : 1200px
);
$fontsize: (
        'xs' : 0.75rem,
        'sm' : 0.875rem,
        'base' : 1rem,
        'lg' : 1.125rem,
        'xl' :  1.25rem,
        '2xl' : 1.5rem,
        '3xl' : 1.875rem,
        '4xl' : 2.25rem,
        '5xl' : 3rem,
        '6xl' : 3.75rem,
        '7xl' : 4.5rem,
        '8xl' : 6rem,
        '9xl' : 8rem
);

$lineheight: (
        'xs' : 1rem,
        'sm' : 1.25rem,
        'base' : 1.5rem,
        'lg' : 1.75rem,
        'xl' :  1.75rem,
        '2xl' : 2rem,
        '3xl' : 2.25rem,
        '4xl' : 2.5rem,
        '5xl' : 1,
        '6xl' : 1,
        '7xl' : 1,
        '8xl' : 1,
        '9xl' : 1
);



@mixin fontSize($key_viewport, $value_viewport, $key_fontsize, $value_fontsize) {
    @if $key_viewport == '' {
        .font-size-#{$key_fontsize}{
            font-size: $value_fontsize !important;
            line-height: map-get($lineheight,  $key_fontsize )  !important;
        }
    }
    @else{
        @media(min-width: $value_viewport){
            .font-size-#{$key_viewport}-#{$key_fontsize}{
                font-size: $value_fontsize  !important;
                line-height: map-get($lineheight,  $key_fontsize )  !important;
            }
        }
    }
}


@each $key_viewport, $value_viewport in $viewport {
    @each $key_fontsize, $value_fontsize in $fontsize {
        @include fontSize($key_viewport, $value_viewport, $key_fontsize, $value_fontsize);
    }
}
