.testimonials {

    display:flex;
    align-items:center;
    flex-direction: column;
    max-width:800px;

    .carousel-item {
        
        text-align:center;

        cite {
            display:block;
            text-align:center;
            margin-bottom:20px;
    
        }
    
        .author, .position {
            display:block !important;
            margin:0;
            padding:0;
        }
    
    }

}