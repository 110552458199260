.flat {

    // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    // general

    & * {
        //margin:0;
        //padding:0;
        box-sizing: border-box;
    }

    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin: auto;
    padding: 1.5rem 3rem;
    max-width: 1980px;
    gap: 1rem;
    font-family: "Poppins", sans-serif;
    visibility: hidden;

    .flat-logo {
        display: inline-flex;
        position: relative;
        justify-content: flex-start;
        align-items: center;

        a {
            padding-top: 0.3rem;
            padding-bottom: 0.3rem;
            margin-right: 1rem;

            img {
                width: 220px;
                max-width: 220px;
            }
        }

    }

    .flat-button {
        width: 30px;
        aspect-ratio: 1/1;
        display: none;
        cursor: pointer;
        position: relative;
        justify-content: center;
        align-items: center;

        img {
            width: 30px;
            aspect-ratio: 1/1;
            object-fit: contain;
        }
    }

    .flat-menu {
        list-style-type: none;
        position: relative;
        display: inline-flex;
        justify-content: flex-end;
        width: 100%;

        & > ul {
            list-style-type: none;
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            // flex-wrap: nowrap;
            line-height: 1.6rem;
            font-size: 1rem;
            padding:0;
            margin:0;

            & > li {
                display: list-item;


                & > a {
                    display: block;
                    padding: 0.5rem 0.938rem !important;
                    white-space: nowrap;
                    text-decoration: none !important;
                    color: #1B3A6C;
                    font-weight: 600;


                }


                &:hover > a {
                    color: #13afe1 !important;
                }
            }
        }
    }

    // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    // mobile
    &.flat-in {
        padding: 0.5rem 1rem;

        .flat-logo {
            a {
                img {
                    width: 170px;
                    max-width: 170px;
                }
            }

        }

        .flat-button {
            display: inline-flex;

            & > .flat-button--inactive{
                display:block;
            }
            &.active > .flat-button--inactive{
                display:none;
            }

            & > .flat-button--active{
                display:none;
            }
            &.active > .flat-button--active{
                display:block;
            }
        }

        .flat-menu {
            overflow: auto;
            display: none;
            justify-content: space-between;
            &.show{
                display:inline-flex;
            }

            position:fixed;
            top:55px;
            left:0;
            width:100dvw;
            height:calc(100dvh - 56px );

            background:white;
            z-index:-1;
            //padding-top: 56px;
            flex-direction: column;

            & > ul{
                display: flex;
                flex-direction: column;
                padding-left: 0;
                margin-bottom: 0;
                list-style: none;

                & > li {
                    width:100%;

                    & > a {
                        display:flex;
                        width:100%;
                        padding: 0.5rem 1rem;
                        text-transform: uppercase;
                        border-bottom: 1px solid #EEEEEE;
                        position: relative;
                        font-weight: normal;

                        &:after{
                            position: absolute;
                            display: flex;
                            top: 0;
                            right: 0.5rem;
                            width: auto;
                            aspect-ratio: 1 / 1;
                            height: 100%;
                            max-width: 40px;
                            justify-content: center;
                            align-items: center;
                            content: "\f061";
                            font-family: "Font Awesome 5 Free";
                            font-size: 1.1rem;
                            font-weight: bold;
                        }
                    }
                }

            }

            & > .flat-menu-bottom{
                display: flex;
                flex-direction: column;

                .dc__language-container {
                    margin-top: 0rem;
                    border-top: 1px dashed #BBBBBB;
                    padding: 0.5rem 0;
                    .dc__language-title {
                        font-size: $font-size-base;
                        font-weight: normal;
                        color: $iss-dark-gray;
                        padding: 0.5rem 1rem 0.5rem;
                        margin:0;
                    }

                    .dc__language-list {
                        padding:  0.5rem 0 0;

                        .dc__language-item {
                            a {
                                padding: 0 1rem 0.5rem;
                                text-transform: capitalize;
                                color: $iss-light-gray;
                                border: none;

                                &:after {
                                    display:none;
                                }
                            }
                        }
                    }
                }

                .dc__policy-container {
                    border-top: 1px dashed #BBBBBB;

                    .dc__policy-list {
                        padding:  0.5rem 0 0;
                        .dc__policy-item {
                            a {
                                padding: 0 1rem 0.5rem;
                                text-transform: none;
                                color: $iss-dark-gray;
                                border: none;

                            }
                        }
                    }
                }
            }

        }


        .d-flat-in{
            display:flex !important;
        }
    }

    // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    // desktop
    &.flat-out {
        padding: 1.5rem 3rem;

        .flat-button {
            display: none;
        }

        .flat-menu {
            display: inline-flex;
        }

        .d-flat-out{
            display:flex !important;
        }
    }


}



header{
    position: fixed;
    display: flex;
    justify-content: center;
    top:0;
    left:0;
    width:100%;
    box-sizing: border-box;
    background: white;
    z-index:10;
    height:56px;

    @media(min-width: 992px){
        height:96px;
    }

}

body{
   padding-top:56px;
    @media(min-width: 992px){
        padding-top:96px;
    }
}

