.register {
    position: relative;

    & > .container {
      @media screen and (max-width: 768px) {
        margin: 0;
        padding: 0;
      }
    }

    &.section-content {
      padding: 1rem 4rem;

      @media screen and (max-width: 768px) {
        padding: 1rem;
      }
    }

    .register-header {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 15px;
      margin-bottom: 30px;

      @media screen and (max-width: 768px) {
        margin-bottom: 20px;
      }
    }

    h1.title {
      font-family: 'Poppins', sans-serif;
      text-align: center;
      font-size: 43px;
      font-weight: 700;
      line-height: 52px;
      margin-bottom: 0;
      
      @media screen and (max-width: 768px) {
        font-size: 23px;
        line-height: 32px;
      }
    }
    
    p.subtitle {
      font-family: 'Poppins', sans-serif;
      font-size: 18px;
      line-height: 27px;
      color: #212529;
      text-align: center;

      @media screen and (max-width: 768px) {
        margin-bottom: 0;
      }
    }

    #registerForm .form-group {
      margin-bottom: 20px;

      @media screen and (max-width: 768px) {
        margin-bottom: 0;
      }
    }

    #registerForm .form-group > div {
      @media screen and (max-width: 768px) {
        margin-bottom: 20px;
      }
    }
    
    .registration-questions {
      display: flex;
      flex-direction: column;
      margin-top: 21px;
      
      &:last-child {
        margin-bottom: 21px;
      }

      .registration-wrap {
        display: flex;
        gap: 20px;
        
        @media screen and (max-width: 768px) {
          flex-direction: column;
          gap: 10px;
        }
      }

      .registration-question {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        margin-bottom: 5px;
      }

      .registration-option {
        display: flex;
        align-items: center;
        gap: 10px;

        label {
          margin-bottom: 0;
          color: #696B6B;
          font-size: 16px;
          font-weight: 400;
          font-family: 'Poppins', sans-serif;

          @media screen and (max-width: 768px) {
            font-size: 14px;
          }
        }

        input[type="radio"] {
          -webkit-appearance: none;
          appearance: none;
          background-color: #696B6B;
          margin: 0;
          font: inherit;
          color: #C5C5C5;
          width: 1.25em;
          height: 1.25em;
          border: 0.25em solid #C5C5C5;
          border-radius: 50%;
          transform: translateY(-0.075em);
          display: grid;
          place-content: center;
        }

        input[type="radio"]::before {
          content: "";
          width: 1.25em;
          height: 1.25em;
          border-radius: 50%;
          transform: scale(0);
          transition: 120ms transform ease-in-out;
          background-color: #696B6B;
        }

        input[type="radio"]:checked {
          background-color: #6BBB4C;
        }

        input[type="radio"]:focus {
          outline: none;
          outline-offset: none;
        }
      }
    }

    .register-demo,
    .register-demo-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .register-demo-clients {
      display: flex;
      gap: 20px;
      align-items: center;

      img {
        display: block;
        object-fit: contain;
      }
    }

    .register-demo-title {
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      text-align: center;
      margin-bottom: 20px;
    }

    .register-demo-info {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      margin-bottom: 0;
    }

    .btn-register-demo {
      display: grid;
      place-items: center;
      width: 144px;
      height: 40px;
      color: #FFF;
      background-color: #A6A6A6;
      border-radius: 10px;
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;
    }

    .register-modal {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        &.hidden { display: none; }

        .register-modal-overlay {
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
            width: 100%;
            height: 100%;
            background-color: rgba(255,255,255,.5);
        }
        
        .register-modal-content {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            position: relative;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);

            max-width: 320px;
            padding: 20px 20px 0;

            border-radius: 5px;
            background-color: #FFF;
            box-shadow: 0px 0px 12px -1px rgba(0,0,0,0.15);
            z-index: 2;

            .register-modal-message {
                margin-top: 20px;
                p { 
                    font-size: 16px; 
                    text-align: center;
                    color: #0EAEDF;
                }
            }
        }
    }

    .error {
        background-color:#f05362;
        color:white;
        font-weight:400;
        padding: 5px 16px;
    }
    button.disabled {
        pointer-events: none;
        opacity: .5;
    }
}

.register-button-start{
  font-size: 20px;
  padding: 16px 50px !important;
  font-weight: 600 !important;
  line-height: 30px;
}

.lds-roller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
  }
  .lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #0EAEDF;
    margin: -4px 0 0 -4px;
  }
  .lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
  }
  .lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
  }
  .lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
  }
  .lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
  }
  .lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
  }
  .lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
  }
  .lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
  }
  .lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
  }
  .lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
  }
  .lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
  }
  .lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
  }
  .lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
  }
  .lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
  }
  .lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
  }
  .lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
  }
  .lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
  }
  @keyframes lds-roller {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

.field-error{
  color: #f05362;
  border-color: #f05362;
}

.input-register{
  border-radius: 10px;
}

.error_message{
  font-weight: unset !important;
  margin-bottom: 5px;
}