.casestudies .section-content,
.insights .section-content  {

    h2 {

        -webkit-text-fill-color: initial;
        font-weight: 900;
        font-size: 2rem;
        margin-bottom:30px;
        line-height:2.5rem;

        @include media-breakpoint-down(lg) {
            font-size:1.5rem;
            line-height: 2rem;
        }

        @include media-breakpoint-down(md) {
            font-size:1.2rem;
            line-height: 1.5rem;
            text-align:left;
        }

    }

    li {
        margin-bottom:20px;
    }


}