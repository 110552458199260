//
.text--iss-light-green{
    color: $iss-light-green !important;
}
.text--iss-dark-blue{
    color: $iss-dark-blue !important;
}
.text--iss-light-blue{
    color: $iss-light-blue !important;
}

.text--iss-light-gray{
    color: $iss-light-gray !important;
}
.text--iss-dark-gray{
    color: $iss-dark-gray !important;
}


.font--size-base{
    font-size: $font-size-base !important;
}
.font-weight-semibold{
    font-weight: 600 !important;
}


.gap-0{
    gap:0;
}
.gap-1{
    gap: .25rem;
}
.gap-2{
    gap: .50rem;
}
.gap-3{
    gap: .75rem;
}
.gap-4{
    gap: 1rem;
}
.gap-5{
    gap: 1.50rem;
}





.box-outline {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    & > span {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        padding: 0;
        border: none;
        outline: none;
        position: relative;
        z-index: 1;
        border-radius: 20px;
        background: linear-gradient(90deg, #13afe1, #67bc45);
        cursor: pointer;
        width:100%;

        & > span{
            text-align: center;
            display: inline;
            padding: 1.5rem 0.5rem;
            font-size:  1rem;
            font-weight: 700;
            line-height: 1;
            background: linear-gradient(90deg, #13afe1, #67bc45);
            -webkit-background-clip: text;
            color: transparent;

            @media(min-width:768px){
                font-size:  clamp(0.8rem, 2vw, 1.1rem);
            }

            @media(min-width:992px){
                font-size: clamp(0.8rem, 2.2vw, 1.3rem);
            }
        }
    }
    & > span::before {
        content: "";
        position: absolute;
        left: 1px;
        right: 1px;
        top: 1px;
        bottom: 1px;
        border-radius: 19px;
        background-color: white;
        z-index: -1;
        transition: 200ms
    }


    &.box-outline--blue{
        & > span{
            background: transparent;
            border: 2px solid $hiw_blue;
            & > span {
                background: transparent;
                color: $hiw_blue;
            }
        }
    }

    &.box-outline--teal{
        & > span{
            background: transparent;
            border: 2px solid $hiw_teal;
            & > span {
                background: transparent;
                color: $hiw_teal;
            }
        }
    }

    &.box-outline--green{
        & > span{
            background: transparent;
            border: 2px solid $hiw_green;
            & > span {
                background: transparent;
                color: $hiw_green;
            }
        }
    }


    //& > span:hover::before {
    //    opacity: 50%;
    //    top: 0px;
    //    right: 0px;
    //    bottom: 0px;
    //    left: 0px;
    //}
    //& > span:hover::after{
    //    color: white;
    //}
}







.gradient-text{
    background: linear-gradient(to right, #13AFE1, #67BC45);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.gradient-bg {
    background: linear-gradient(to right, #13AFE1, #67BC45);
    color:white;
}






