.section-content.clients {

    .row {
        justify-content:center;

        p {
            display:inline-block;
            max-width:950px;
            text-align:center;

            @include media-breakpoint-down(lg) {
                max-width:800px;
            }

            @include media-breakpoint-down(md) {
                max-width:600px;
            }


        }

    }



    .logos, .catalogues {
        display:flex;
        justify-content:center;

        img {
            margin:0px;
        }


    }

    .logos {

        .logos-wrapper {
            margin:auto;
            max-width:992px;
            display:flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;

            img {
                width:50%;

                @include media-breakpoint-up(sm) {
                    width:33%;
                }
                @include media-breakpoint-up(md) {
                    width:20%;
                    max-width:200px;
                }
            }
        }
 
 
        .logos-wrapper.logos-wrapper-half-row {

            img {
                width:50%;

                @include media-breakpoint-up(sm) {
                    width:32%;
                }
                @include media-breakpoint-up(md) {
                    width:50%;
                }
                @include media-breakpoint-up(lg) {
                    width:33%;
                }
            }
        }

    }

    .catalogues {
        margin-bottom:20px;

        img {
            max-width:200px;
            margin:10px;
            padding:0;
            border:1px solid rgb(204, 204, 204);
            @include media-breakpoint-down(md) {
                max-width:175px;
            }
        }
    }


}
