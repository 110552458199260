.menu-locales {
    position: relative;

    &__button {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 3px;
        border: 0;
        color: $iss-light-blue;
        fill: $iss-light-blue;
        font-size: 1rem !important;
        cursor: pointer;
        position: relative;
        text-transform: uppercase;
        text-decoration: none;

        &:hover {
            color: $iss-light-blue;
            fill: $iss-light-blue;
            text-decoration: none;
        }

        img{
            width: calc(#{$font-size-base} * 1.5);
            height: calc(#{$font-size-base} * 1.5);
            object-fit: contain;
        }
    }

    &__list_wrapper {
        background-color: #fff;
        border: 1px solid $iss-dark-blue;
        border-radius: 10px;
        padding: 10px;
        position: absolute;
        top: calc(100% + 20px);
        top: calc(100% + 7px);
        right: 0;
        display: none;
        width: auto;
        height: auto;
        min-width:160px;
    }

    &__list_title {
        color: $iss-dark-blue;
        font-size: $font-size-base;
        white-space: nowrap;
        position:relative;
        margin-bottom:10px;
        padding-bottom:5px;

        &::before{
            border-bottom: 1px solid $iss-dark-blue;
            content: "";
            display: block;
            width: 100%;
            height: 1px;
            bottom: 0;
            position: absolute;
        }
        &::after{
            background-color: #fff;
            border-bottom: 1px solid $iss-dark-blue;
            border-right: 1px solid $iss-dark-blue;
            content: "";
            display: block;
            width: 8px;
            height: 8px;
            bottom: 0;
            left:50%;
            position: absolute;
            transform: translate(-50%, 50%) rotate(45deg);
        }
    }

    &__list {
        list-style-type: none;
        margin: 0;
        position: relative;
        padding: 0;

        &_item {
            margin: 3px 0 10px;

            &:last-child{
                margin: 3px 0;
            }

            a {
                color: $iss-light-gray;
                display: flex;
                font-size: $font-size-base;
                gap:10px;
                line-height: calc(#{$font-size-base} * 1.5);
                text-decoration: none !important;

                img{
                    width: calc(#{$font-size-base} * 1.5);
                    height: calc(#{$font-size-base} * 1.5);
                    object-fit: contain;
                    //margin-right: 10px;
                }
            }
        }

    }



    &.active  &__list_wrapper{
        display: none;
        @media(min-width:992px){
            display:block;
        }
    }

}
