// price box theme
@mixin licence-theme($color) {
    background: $color;
    background: rgba($color,0.10);


    h5 {
        color:$color;
    }

    .includes {
        border-color:$color;
    }

}

@mixin licence-theme-solid($color) {
    background: $color;


    h5 {
        color:$color;
    }

    .includes {
        border-color:$color;
    }

}

//variables
$free-licence-color: rgb(103, 188, 69);
$start-licence-color: rgb(19, 175, 225);
$design-licence-color:rgb(25, 59, 108);
$sell-licence-color:rgba(84, 0, 100, 1);
$pro-licence-color:rgb(185, 129, 207);
$enterprise-licence-color:rgba(251, 235, 143, 0.8);
$enterprise-title-color:rgba(124, 57, 40, 1);

.slideAnimation {
    animation-name: slideinout;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-delay: 1s;
    transition: all .25s ease;

    @keyframes slideinout {
        0% { transform: translateX(0); }
        10% { transform: translateX(-150px); }
        20% { transform: translateX(0); }
    }
}

.with-overflow {
    overflow-x: auto;

    &::-webkit-scrollbar {
        opacity: 0;
    }

    @include media-breakpoint-up(md) {
        overflow: unset;
    }
}

.compare-features {
    padding: 1rem 0 0 1rem;

    @include media-breakpoint-up(md) {
        padding: 1rem;
    }

    .pricing-header {
        p {
            margin-bottom: 0;

            @include media-breakpoint-up(md) {
                margin-bottom: 30px;
            }
        }
    }
}

.spacing-mobile {
    padding: 0 !important;
}

.plans {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 1400px;
    gap: 20px;
    overflow-x: auto;

    @include media-breakpoint-up(md) {
        display:grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap:20px;
        column-gap:20px;
        place-items: center;
        margin: 0 auto;
        padding:0 15px 20px 15px;
        width: 100%;
        max-width: 1300px;
        animation-name: unset;
    }

    @include media-breakpoint-up(lg) {
        grid-template-columns: repeat(4, 1fr);
    }

    .plan {
        padding:10px 0;
        overflow:hidden;
        display:flex;
        flex-direction:column;
        align-self: center;
        position: relative;
        width: 100%;
        max-width: 80%;
        height:520px;
        border-radius: 20px;

        @include media-breakpoint-up(md) {
            width: 100%;
            max-width: 311px;
        }

        &.es {            
            @include media-breakpoint-up(md) {
                height:600px;
            }
        }

        &.ro {            
            @include media-breakpoint-up(md) {
                height:550px;
            }
        }

        &.fr {            
            @include media-breakpoint-up(md) {
                height:560px;
            }
        }

        &:first-child {
            margin-right: 20px;

            @include media-breakpoint-up(md) {
                margin-right: unset;                    
            }
        }

        h5 {
            text-align:center;
            color:white;
            font-size:1.9rem;
            line-height:3rem;
            font-weight: 600;
            position: absolute;
            top: 10px;
            width: 100%;
        }

        p {
            padding: 0 35px;
            margin-bottom: 0;
            font-size: 0.75rem !important;

            strong {
                font-weight: 700 !important;
            }

            &.plan-info {
                position: absolute;
                top: 65px;
            }

            @include media-breakpoint-up(md) {
                font-size: 0.87rem !important;
            }
        }

        .price {
            display:flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size:1.5rem;
            line-height:1.75rem;
            text-align:center;
            font-weight: 700;
            position: absolute;
            top: 155px;
            left: 2%;
            width: 97%;
            margin: 0;
            padding: 20px 0;
            border-top: 1px solid rgba(0,0,0, 0.1);
            border-bottom: 1px solid rgba(0,0,0, 0.1);

            .currency {
                font-size:1.75rem;
            }
            .period {
                font-size:1rem;
            }
            p {
                padding:0;
                margin:0;
                font-size:1.3rem !important;

                span {
                    font-weight: 400 !important;
                }
            }

            .noprice {
                min-height:60px;
            }

            .save {
                font-size:0.8rem;
                display:block;
            }

            .cta {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px 0 0;
                margin:0;
                align-self:center;

                a {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: fit-content;
                    min-width: 120px;
                    padding: 0 0.5rem;
                    height: 39px;
                    font-size: 1rem;
                    font-weight: 500;
                    border-radius: 5px;
                }
            }

        }

        .quickinfo-items {
            position: absolute;
            top: 330px;
            margin-bottom: 0;
            padding-right: 6px;

            .quickinfo-item {
                font-size: 0.75rem !important;

                @include media-breakpoint-up(md) {
                    font-size: 0.87rem !important;
                }
            }
        }

        .recommended-for {
            position: absolute;
            top: 460px;
            padding: 0 10px;
            width: 100%;
        }

        &.es .recommended-for {            
            top: 459px;

            @include media-breakpoint-up(md) {
                top: 527px;
            }
        }

        &.ro .recommended-for {            
            @include media-breakpoint-up(md) {
                top: 480px;
            }
        }

        &.fr .recommended-for {            
            @include media-breakpoint-up(md) {
                top: 500px;
            }
        }

        .includes {

            display:flex;
            justify-content:stretch;
            align-items:center;
            flex-grow:0;
            margin:0 10px;
            border-bottom:1px dashed;
            font-size:0.7rem;
            line-height:1.3rem;
            padding:5px 0;

            @include media-breakpoint-up(md) {
                font-size: 0.87rem !important;
            }

            span {
                flex-grow:1;
            }

            strong {
                flex-grow:0;
                text-align: right;
            }

            a {
                text-decoration:underline;
            }

        }

        .options .includes:last-child {
            border:0;
            margin-bottom:20px;
        }

    }

    .plan.free-licence {
        border: 1px solid $free-licence-color;
        @include licence-theme($free-licence-color);
        order: 1;

        @include media-breakpoint-up(md) {
            order: unset;
        }
    }

    .plan.start-licence {
        border: 1px solid $start-licence-color;
        @include licence-theme($start-licence-color);
    }

    .plan.design-licence {
        border: 1px solid $design-licence-color;
        @include licence-theme($design-licence-color);
    }

    .plan.sell-licence {
        border: 1px solid $sell-licence-color;
        @include licence-theme($sell-licence-color);
    }

    .pro-licence {
        border: 1px solid $pro-licence-color;
        @include licence-theme($pro-licence-color);
    }

    .plan.enterprise-licence {
        border: 1px solid $enterprise-licence-color;
        @include licence-theme($enterprise-licence-color);
    }
}


.legend {
    p {
        span {
            font-weight: 700;
            color:$iss-light-blue;
        }
    }
}

.extras {

    ul {
        list-style-type: disc;
    }

}

.new-enterprise {
    @include licence-theme($enterprise-licence-color);
    //border:1px solid rgb(197, 195, 195);
    //background-color:#f3f3f3;
    margin:15px 15px;
    padding:20px;

    h5 {
        font-size:1.8rem;
    }

    @include media-breakpoint-down(sm) {
        text-align: center;
    }

}

.compare-plans {
    max-width: 1370px;
    width: 100%;

    @include media-breakpoint-up(md) {
        & > * { 
            white-space: nowrap; 
        }
    }

    a {
        text-decoration:underline !important;

        span {
            width: 100px;

            @include media-breakpoint-up(md) {
                width: 80%;
            }
        }
    }

    p {
        font-size: 0.7rem !important;
        margin:0px;

        @include media-breakpoint-up(md) {
            font-size: 1rem !important;
        }
    }

    svg {
        width: 14px;
        height: 14px;
        
        @include media-breakpoint-up(md) {
            width: 24px;
            height: 24px;
        }
    }

    thead {
        background: #FFF;
        position: sticky;
        top: 2px;
        z-index: 2;
    }

    thead tr > *:first-child {
        position: sticky;
        left: 0;
        z-index: 2;
        background-color: #FFF;

        @include media-breakpoint-up(md) {
            position: static;
        }
    }

    th,
    td {
        border-top:0;
        border-bottom:1px;
        padding: 0.2rem;
        font-size: 0.7rem;
        
        @include media-breakpoint-up(md) {
            padding: 0.75rem;
            font-size: 1rem;
        }
    }

    @media (min-width: 1200px) {
        & > thead {
            top: 112px;
            top: 95px;
        }

        & * {
            white-space: unset;
        }
    }

    th > .compare-plans-header-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 100px;
        
        @include media-breakpoint-up(md) {
            height: 150px;
        }
    }

    th .compare-plans-header-wrapper > .compare-plans-header-title {
        font-size: 16px !important;
        font-weight: 600;
        
        @include media-breakpoint-up(md) {
            font-size: 24px !important;
        }
    }

    th.free-licence .compare-plans-header-wrapper .compare-plans-header-title {
        color: $free-licence-color;
    }

    th.start-licence .compare-plans-header-wrapper .compare-plans-header-title {
        color: $start-licence-color;
    }

    th.design-licence .compare-plans-header-wrapper .compare-plans-header-title {
        color: $design-licence-color;
    }

    th.sell-licence .compare-plans-header-wrapper .compare-plans-header-title {
        color: $sell-licence-color;
    }

    th.enterprise-licence .compare-plans-header-wrapper .compare-plans-header-title {
        color: $enterprise-title-color;
    }

    .picing-compare-plans {
        line-height: 14px;
        font-weight: 400;
        
        @include media-breakpoint-up(md) {
            line-height: 24px;
        }

        .currency {
            font-weight: 700;
        }

        p {
            font-size: 0.7rem !important;

            @include media-breakpoint-up(md) {
                font-size: 1rem !important;
            }
        }
    }

    tr:not(.compare_separator):nth-child(even) {
        background-color: rgba(222, 222, 222, 0.3);
    }

    .compare_separator {
        font-size: 20px;
        border-bottom: 1px solid #000;

        th {
            position: sticky;
            left: 0;
            background-color: #FFF;
            z-index: 2;

            @include media-breakpoint-up(md) {
                position: static;
            }            
        }
    }

    .information-left {
        width: 150px;
        background-color: #FFF;
        position: sticky;
        left: 0;
        background-color: #FFF;
        z-index: 2;

        @include media-breakpoint-up(md) {
            width: 300px;
            position: static;
        }

        a {
            display: flex;
            width: 150px;
            height: 100%;
            align-items: center;
            justify-content: space-between;

            @include media-breakpoint-up(md) {
                width: 100%;
            }
        }
    }

    .information-left > a,
    .information-left > p,
    .information-left {
        font-weight: 400;
    }


    .free-licence {
        @include licence-theme($free-licence-color);
    }

    .start-licence {
        @include licence-theme($start-licence-color);
    }

    .design-licence {
        @include licence-theme($design-licence-color);
    }

    .sell-licence {
        @include licence-theme($sell-licence-color);
    }

    .pro-licence {
        @include licence-theme($pro-licence-color);
    }

    .enterprise-licence {
        @include licence-theme-solid($enterprise-licence-color);
    }

    .cta {
        margin:5px 0;
        a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: fit-content;
            min-width: 100px;
            height: 19px;
            font-size: 0.75rem;
            font-weight: 500;
            text-decoration: none !important;
            border-radius: 5px;
            padding: 0 0.5rem;
            margin: 0 auto;

            @include media-breakpoint-up(md) {
                min-width: 120px;
                height: 29px;
                font-size: 1rem;
            }
        }
    }

}

// i {
//     display:inline-block;
//     border-radius:50%;
//     text-align: center;
//     width: 20px;
//     height: 20px;
//     line-height:1.3rem;
//     text-indent:0;
//     background-color:white;
//     z-index:1;
//     color:white;
//     min-width: 20px;

// }

// i.fa-check {
//     // background-color:$iss-light-green !important;
//     color: #000;
//     background-color: transparent;
// }

// i.fa-times {
//     background-color:rgb(252, 160, 160) !important;
// }
