.try-button, .contact-button {
    background-image: linear-gradient(to right, #0EAEDF, #67BC45);
    border-radius: 5px;
    border: transparent;
    padding: 0.2rem 1rem;
    color: white;
    font-size: 0.875rem;
    font-weight: 200;

    @media (min-width: 992px) {
        padding: 0.3rem 1rem;
    }
}

.contact-button {
    @media (max-width: 450px) {
        display: none;
    }
}


.navbar-toggler {
    border: none;
    padding-right:0;
}

.navbar-toggler {
    .navbar-toggler-icon {
        background-image: url("/images/icons/common/icon-burger.svg");
    }

    &[aria-expanded="true"] .navbar-toggler-icon {
        background-image: url("/images/icons/common/icon-times.svg");
    }
}

.navbar-light .navbar-nav .nav-link {
    color: #1B3A6C;
}

.navbar-light .navbar-nav .nav-link:hover {
    color: #13afe1;
}

.logo-desktop {
    display: block;
    width: 100%;
    max-width: 170px !important;

    @media(min-width:992px){
        //max-width: 185px !important;
    }
    @media(min-width:1200px){
        //max-width: 220px !important;
    }

    @media(min-width:1300px){
        max-width: 220px !important;
    }


}

.navbar-style {
    max-width: 100%;
}

@media (min-width: 768px) {
    .navbar-style {
        padding-left: 3.9375rem;
        padding-right: 3.9375rem;
    }

    .logo-desktop {
        max-width: 220px;
    }
}

@media (min-width: 992px) {
    .navbar-expand-lg .navbar-nav .nav-link {
        padding: 0.5rem 0.9rem;
    }
    .navbar-expand-xl .navbar-nav .nav-link {
        padding: 0.5rem 0.78rem !important;
    }
}


@media (max-width: 1199px) {

    .navbar-collapse {
        position: fixed;
        display: flex;
        justify-content: space-between !important;
        align-items: stretch;
        flex-direction: column;
        width: 100%;
        height: calc(100vh - 88px);
        height: calc(100dvh - 88px);
        top: 88px;
        left: 0;
        background-color: #fff;
        margin-top: 0;
        overflow: auto;


        ul {
            li {
                &.nav-item--sign {
                    a {
                        padding: 15px 20px;
                        border-bottom: 1px dashed #EEEEEE;

                        &:hover {
                            color: $iss-light-blue !important;
                        }
                    }
                }

                &.nav-item--language {
                    a {
                        &:after {
                            content: '';
                        }
                    }
                }

                &.nav-item {
                    a {
                        padding: 0.5rem 1rem;
                        text-transform: uppercase;
                        border-bottom: 1px solid #EEEEEE;
                        position: relative;
                        font-weight: normal;

                        &:after {
                            position: absolute;
                            display: flex;
                            top: 0;
                            right: 0.5rem;
                            width: auto;
                            aspect-ratio: 1/1;
                            height: 100%;
                            max-width: 40px;
                            justify-content: center;
                            align-items: center;
                            content: "\f061";
                            font-family: "Font Awesome 5 Free";
                            font-size: 1.1rem;
                            font-weight: bold;
                        }

                        &.last {
                            border: 0;
                        }
                    }
                }
            }
        }

        .menu-bottom {

        }


        .dc__language-container {
            margin-top: 0rem;
            border-top: 1px dashed #BBBBBB;
            padding: 0.5rem 0;
            .dc__language-title {
                font-size: $font-size-base;
                font-weight: normal;
                color: $iss-dark-gray;
                padding: 0.5rem 1rem 0.5rem;
                margin:0;
            }

            .dc__language-list {
                padding:  0.5rem 0 0;

                .dc__language-item {
                    a {
                        padding: 0 1rem 0.5rem;
                        text-transform: capitalize;
                        color: $iss-light-gray;
                        border: none;

                        &:after {
                            display:none;
                        }
                    }
                }
            }
        }

        .dc__policy-container {
            border-top: 1px dashed #BBBBBB;

            .dc__policy-list {
                padding:  0.5rem 0 0;
                .dc__policy-item {
                    a {
                        padding: 0 1rem 0.5rem;
                        text-transform: none;
                        color: $iss-dark-gray;
                        border: none;

                    }
                }
            }
        }

    }
}
