.sla {

    ul {
        list-style-type: none !important;
        margin-bottom:20px;
        li {
            padding-bottom:0.7rem;
        }
    }

    ul.roman-list {
        list-style-type: lower-roman !important;
    }

    ul.latin-list {
        list-style-type: lower-latin !important;
    }

    ul.disc-list {
        list-style-type: disc !important;
    }

}


