.keypoints, .full-width-cta  {
    display:flex;
    margin-top:20px;
    justify-content:center;
    gap:20px;

    div {
        width:350px;
        text-align: center;
        position:relative;
        @include media-breakpoint-down(md) {
            width:275px;
        }
    }
  
}

.keypoints {

    div {
        align-items:center;

        p {
            background: linear-gradient(to right, $iss-light-blue, $iss-light-green);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-weight:700;
            padding:0.5rem 1rem;
            font-size:1rem;
            margin-bottom:0;
            line-height:1.3rem;
            @include media-breakpoint-down(md) {
                line-height:1rem;
            }
        }
    
    }


}