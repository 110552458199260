.full-width-cta {

    display:flex;
    gap:20px;
    margin-top:20px;
    justify-content:center;

    div {
        max-width:350px;
        text-align: center;
        position:relative;
    }

    padding-top:20px;
    gap:20px;


    div {

        a {
            display:block;
            background-image: linear-gradient(to right, $iss-light-blue, $iss-light-green);
            border: transparent;
            color: white;
            padding: 15px 16px;
            text-align: center;
            text-decoration: none;
            font-weight: 400;
            margin-bottom:10px;
        }
    
        a.alternate {
            outline: 1px solid #13afe1;
            color: #13afe1;
            background-image:none;
            background-color:white;
        }

        p {
            margin:0;
            font-size:0.8rem !important;
        }
    }

}

.small-width-cta {

    display:flex;
    gap:0;

    div {
        width:45%;
        min-width:175px;
        text-align: center;
        position:relative;
        padding-right: 10px;

        a {
            display:block;
            background-image: linear-gradient(to right, $iss-light-blue, $iss-light-green);
            border: transparent;
            color: white;
            padding: 15px 16px;
            text-align: center;
            text-decoration: none;
            font-weight: 400;
            margin-bottom:10px;
        }

        p {
            font-size:0.7rem !important;
        }

    }

}

.single-inline-cta {

    align-items:center;

    .cta {

        width:auto;

        p {
            font-size:1.2rem !important;
            font-weight:500;
            text-align:left;

            @include media-breakpoint-down(sm) {
                text-align: center;
            }

        }

    }
}

.cta {

    padding:($spacer x 2) 0px;
    margin-bottom:30px;

    a {
        background-image: linear-gradient(to right, $iss-light-blue, $iss-light-green);
        border: transparent;
        color: white;
        padding: 10px 16px;
        text-align: center;
        text-decoration: none;
        font-weight: 400;
    }

    a.alternate {
        border: 1px solid #13afe1;
        color: #13afe1;
        background-image:none;
        background-color:white;
    }
}
