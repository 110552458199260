.box-card-resources{
    position:relative;
    display:flex;
    height: 100%;

    .box-card-resources__link{
        font-family: Poppins, sans-serif;
        color: #000;
        padding:0 0 2.5rem;
        line-height: 1.4;
        font-size: 16px;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        height: 100%;
        text-decoration: none !important;
    }

    .box-card-resources__image{
        width:100%;
        aspect-ratio: 400/266;
        margin:0 0 1.2rem;
        object-fit: contain;
    }

    .box-card-resources__title{
        color: $hiw_blue;
        font-family: Poppins, sans-serif;
        font-weight: 500;

        line-height: 1.4;
        font-size:1.1rem;

        margin:0 0 1.2rem;
        text-align:center;
    }

    .box-card-resources__description{
        width: 100%;
        font-weight: 400;

        line-height: 1.4;
        text-align: justify;

        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;

        margin:0 0 1rem;
    }

    .box-card-resources__cta{
        text-decoration: none;
        font-weight: 400;
        display:block;
        width:100%;
        border:1px solid #D8D8D8;
        border-radius:10px;
        padding:5px 0;
        text-align:center;

    }
}
