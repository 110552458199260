.videowrapper {

    aspect-ratio: 16 / 9;

    video {
        width: 100%;
        height: 100%;
        clip-path: inset(1px 1px);
    }
}

.inline-steps {

    .step {

        font-weight: 700;
        color: $iss-light-green;

        .stepnb {
            display: inline-block;
            border: 1px solid $iss-light-green;
            border-radius: 50%;
            text-align: center;
            width: 30px;
            height: 30px;
            line-height: 30px;
            text-indent: 0;
            background-color: white;
        }

    }

}


.timeline {

    display: flex;
    align-items: stretch;

    img {
        max-width: 400px;
        width: 100%;
    }

    @include media-breakpoint-down(md) {
        flex-direction: column;
        align-items: center;
    }

    .left {
        //border:1px solid red;
        flex-grow: 1;
        display: flex;
        align-items: center;
        flex-basis: 0;
        order: 1;
        justify-content: end;

        div {

            text-align: right;

            @include media-breakpoint-down(md) {
                text-align: center;
                background-color: white;
                margin-top: 5px;
                z-index: 2;
                margin-bottom: 20px;
            }

            h5 {
                color: $iss-light-blue;
                font-weight: 600;
                margin-bottom: 0;
                font-size: 1rem;

            }

            p {
                color: black;
                font-size: 0.8rem;
                font-weight: 400;
                margin-bottom: 0;
            }
        }

        @include media-breakpoint-down(md) {
            position: relative;
            order: 2;
            height: 80px;
        }

    }

    .left:before {
        @include media-breakpoint-down(md) {
            content: " ";
            position: absolute;
            border-right: 1px dashed $iss-light-blue;
            left: 50%;
            top: 25px;
            height: calc(100% - 25px);
            z-index: 1
        }
    }

    .right {
        //border:1px solid red;
        flex-grow: 1;
        display: flex;
        align-items: center;
        flex-basis: 0;
        order: 3;

        img {
            z-index: 2;
        }

        @include media-breakpoint-down(md) {
            position: relative;
            flex-basis: auto;
            padding-top: 5px;
            padding-bottom: 60px;
            margin-bottom: 5px;
        }

    }

    .right:before {
        @include media-breakpoint-down(md) {
            content: " ";
            position: absolute;
            border-right: 1px dashed $iss-light-blue;
            left: 50%;
            top: 0px;
            height: calc(100%);
            z-index: 1
        }
    }


    .center {
        //border:1px solid red;
        flex-grow: 0;
        padding: 0 30px;
        display: flex;
        align-items: center;
        order: 2;

        @include media-breakpoint-down(md) {
            order: 1;
        }

        .stepnb {
            display: inline-block;
            border: 1px solid $iss-light-blue;
            border-radius: 50%;
            text-align: center;
            width: 40px;
            height: 40px;
            line-height: 40px;
            text-indent: 0;
            background-color: white;
            z-index: 1;
        }

    }

    .center:before {
        content: " ";
        position: relative;
        border-right: 1px dashed $iss-light-blue;
        left: 20px;
        top: 1px;
        height: calc(100% - 1px);
        display: block;
        @include media-breakpoint-down(md) {
        }
    }

    .center.first:before {
        top: 25%;
        height: 50%;
        @include media-breakpoint-down(md) {
        }
    }

}


.timeline:nth-child(even) {

    .left {
        order: 3;
        justify-content: start;

        div {
            text-align: left;
            @include media-breakpoint-down(md) {
                text-align: center;
            }
        }

        @include media-breakpoint-down(md) {
            order: 2;
        }
    }

    .right {
        order: 1;
        justify-content: end;

        @include media-breakpoint-down(md) {
            order: 3
        }
    }

}


.timeline:last-child {

    flex-direction: column;
    align-items: center;

    .left {
        h2 {
            display: block;
            max-width: 600px;
            margin-top: 30px;
        }
    }

    .center {
        margin-bottom: 15px;
        align-items: end;
        height: 90px;

        @include media-breakpoint-down(md) {
            height: auto;
        }

        .stepnb {
            background-color: $iss-light-green;
            border: 0px;
            color: white;
        }

    }

    .center:before {
        top: 0;
    }

    .left {
        display: block;
        text-align: center;

        @include media-breakpoint-down(md) {
            height: auto;
        }

        h4 {
            font-size: 2.5vw;
            line-height: 3vw;
        }

        p {
            color: initial;
            font-size: 1vw;
            font-weight: normal;
            margin-bottom: 30px;

            @include media-breakpoint-down(md) {
                margin-bottom: 0;
            }
        }

    }

    .left:before {
        content: none;
    }

    .right {
        flex-basis: auto;
        order: 3;
    }

    .right:before {
        content: none;
    }


}

//
.page-hiw {
    .hiw_title{
        line-height: 1.5;
    }
    .hiw_subtitle{
        line-height: 1.5;
    }



    .hiw-btn {
        position: relative;
        padding: 1.1rem;
        margin: 0.5rem 0;
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        font-family: 'Poppins';
        font-weight: bold;
        font-size: 1rem;
        line-height: 1.3;
        text-decoration: none !important;
        border-radius: 20px;
        text-align: left;

        @media(min-width: 992px) {
            position: relative;
            padding: 1.5rem 1rem;
            margin: 1rem 0;
            display: inline-flex;
            justify-content: space-between;
            width: 100%;
            font-family: 'Poppins';
            font-weight: bold;
            font-size: 1.3rem;
            line-height: 1.8rem;
            text-decoration: none !important;
            border-radius: 20px;
        }

        &.hiw-btn-blue {
            background-color: $hiw_blue;
            color: white;
        }

        &.hiw-btn-teal {
            background-color: $hiw_teal;
            color: white;
        }

        &.hiw-btn-green {
            background-color: $hiw_green;
            color: white;
        }


        &.hiw-btn-outline {
            display: inline-flex;
            width: auto;
            justify-content: center;
            align-items: center;
            position: relative;
            padding: 1rem 0.5rem;
            box-sizing: border-box;

            -webkit-text-fill-color: transparent;
            background: linear-gradient(90deg, #13afe1, #67bc45);
            background-clip: text;
            -webkit-background-clip: text;

            font-size: 1.3rem;
            font-weight: 700;
            line-height: 1.8rem;
            border: 2px solid $hiw_blue;
            border-radius: 20px;
        }
    }


    .hiw-video-container {
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: $hiw_light_blue;
        border-radius: 20px;
        padding: 0.5rem 0.5rem 0;
        box-sizing: border-box;

        @media(min-width:992px){
            border-radius: 30px;
            padding: 1rem 1rem 0.5rem;
        }

        > &--thumb {
            width: 100%;
            border-radius: 14px;

            @media(min-width:992px){
                border-radius: 24px;
            }
        }

        > &--button {
            cursor: pointer;
            width: auto;
            border-radius: 20px;
            border: 1px solid #000;
            background: #fff;
            color: #000;
            padding: .7rem 1.3rem;
            margin: 0.5rem;
            font-weight: 400;
            font-size: clamp(0.8rem, 2.2vw, 1.3rem);
            line-height: 1rem;
        }
    }
}
