.register-region_container {

    .register-region_title {
        font-size: $font-size-base;
        font-weight: normal;
        line-height: calc($font-size-base * 1.31 );
        font-family: "Poppins" ,sans-serif;
        color: #212529;
        margin:0;
    }

    .register-region_paragraph {
        font-size: $font-size-base;
        font-weight: normal;
        line-height: calc($font-size-base * 1.31 );
        font-family: "Poppins" ,sans-serif;
        color: #A6A6A6;
        margin:0;
    }

    .register-region_list {
        position:relative;
        display:flex;
        flex-wrap:wrap;
        list-style-type: none;
        gap: 0.5rem;
        margin:0;
        padding:15px 0 10px;

        .register-region_item {

            label[for^=region_]{
                input[type=radio]{
                    display:none;

                    &:checked{
                        & ~ span{
                            background: $iss-light-green;
                            color: #fff;
                        }
                    }


                }

                span{
                    font-family: "Poppins",sans-serif;
                    padding: 5px 10px;
                    border-radius: 10px;
                    border:1px solid #CFD4DA;
                    font-size: $font-size-base;
                    cursor:pointer;
                }
            }
        }
    }
}
