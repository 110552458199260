.section-content.contact {
 
    h1 {
        background: linear-gradient(to right, #0EAEDF 0%, #67BC45 25%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .contact-submited {
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content:center;
    
        p {
            margin-right:0;
            text-align:center;
        }

    }

    .contact input::placeholder {
        color: #D2D2D2;
        font-size: 0.7rem;
        font-style: italic;
    }

    textarea {
        min-height:150px;
    }

   
}

