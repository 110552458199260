$widthList: (
        "25" : "25%",
        '50': "50%",
        '75': "75%",
        '100': "100%",
        'auto': "auto",
);

$breakpoints: (
    'sm' : '576px',
    'md' : '768px',
    'lg' : '992px',
    'xl' : '1200px'
);


@each $idx_width, $width in $widthList {
    .w-#{$idx_width} {
        width: #{$width};
    }
    .mw-#{$idx_width} {
        max-width: #{$width};
    }

    @each $idx_breakpoint, $breakpoint in $breakpoints {
        .w-#{$idx_breakpoint}-#{$idx_width} {
            @media(min-width:$breakpoint){
                width: #{$width} !important;
            }
        }
        .mw-#{$idx_breakpoint}-#{$idx_width} {
            @media(min-width:$breakpoint){
                max-width: #{$width} !important;
            }
        }
    }
}

