.terms {
    p {
    }
    ul {
        color:inherit;
        li {
        }        
    }
    ul {
        list-style-type:circle;
        margin-top:20px;
        li {
            margin-bottom:0 !important;
        }
        li::before {
            content:none !important;
            padding-left:0px;
        }
    }
    

    ol {
        padding-left:0px;
        margin-top:20px;
        list-style-type:none;
        counter-reset: terms;

        li {
            padding-left:20px;
            margin-bottom:20px;

            p {
                margin:0;
            }

            .formula {
                margin: 20px auto !important;
                display: block;
                text-align: center;
            }

            span {
                display:inline-block;
                margin-bottom:10px;
                font-weight:700;
            }
        }

        li::before  {
            content: counters(terms, ".") " ";
            counter-increment: terms;
            font-weight:500;
            padding-right:5px;
        }


    }

    ol.toc {
        margin:0px;
        
        li {
            margin:0 0 5px 0;
            font-size:14px;
            line-height:18px;
            font-weight:500;
            border-radius:10px;
            border:1px solid;
            padding:8px 5px;

            a {
                text-decoration:none;
            }
        }
    }

    ul.address {
        padding:20px;
        list-style-type: none;
        font-weight:500;
        border:1px solid gray;
    }

    a {
        text-decoration:underline;
    }


}


