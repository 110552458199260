/* custom-dropdown-menu */
.custom-dropdown-menu {
    position: relative;
    display: flex;
    justify-content: center;
    padding: .5rem .938rem !important;


    &.show {
    }

    .custom-dropdown-menu-title {
        text-decoration: none;
        color: #1B3A6C;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.2rem;
        //padding: 0.5rem 0.78rem !important;

        svg {
            fill: #1B3A6C;
        }
    }

    &.show {
        .custom-dropdown-menu-title {
        }
    }

    .custom-dropdown-menu-items {
        display: none;
        position: absolute;
        width: 0;
        max-width: 400px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 10px;

        background-color: #fff;
        border: 1px solid #1d3a6b;
        border-radius: 10px;
        padding: 10px;
        top: calc(100% + 20px);
        top: calc(100% + 0px);
        /*right: 0;*/
        height: auto;
        min-width: 180px;
        overflow: hidden;
    }

    &.custom-dropdown-menu--right > .custom-dropdown-menu-items {
        right: 0 !important;
    }

    &.custom-dropdown-menu--left > .custom-dropdown-menu-items {
        left: 0 !important;
    }

    &.show > .custom-dropdown-menu-items {
        display: flex;
        width: auto;
    }

    .custom-dropdown-menu-items > .custom-dropdown-menu-item {
        position: relative;
        display: block !important;
        width: 100% !important;
        line-height: 24px;
        text-decoration: none !important;
        margin-right:30px;
        text-wrap: nowrap;

        &::after {
            position: absolute;
            display: flex;
            top: 0;
            right: 0;
            width: auto;
            aspect-ratio: 1 / 1;
            height: 100%;
            max-width: 40px;
            justify-content: center;
            align-items: center;
            content: "\f061";
            font-family: "Font Awesome 5 Free";
            font-size: 1.1rem;
            font-weight: bold;
        }

        &:hover {
            color: #10AEDF !important;
            fill: #10AEDF !important;
        }
    }
}


.flat.flat-in .flat-menu .custom-dropdown-menu {
    width: 100%;
    flex-direction: column;
    padding:0px !important;

    .custom-dropdown-menu-title {
        display: flex;
        width: 100%;
        padding: 0.5rem 1rem;
        text-transform: uppercase;
        border-bottom: 1px solid #EEEEEE;
        position: relative;
        font-weight: normal;
        justify-content: start;

        &:after {
            position: absolute;
            display: flex;
            top: 0;
            right: 0.5rem;
            width: auto;
            aspect-ratio: 1 / 1;
            height: 100%;
            max-width: 40px;
            justify-content: center;
            align-items: center;
            content: "\f061";
            font-family: "Font Awesome 5 Free";
            font-size: 1.1rem;
            font-weight: bold;

        }

        svg {
            display: none;
        }
    }

    .custom-dropdown-menu-items{
        position:relative;
        display:flex;
        flex-direction: column;
        top:unset;
        left:unset;
        right: unset;
        padding: 0;
        max-width: unset;
        width:100%;
        gap:0;
        border: unset;
        border-radius: unset;


        .custom-dropdown-menu-item{
            display:flex;
            position:relative;
            padding: 0.5rem 0.78rem 0.5rem 2.2rem !important;
            text-transform: uppercase;
            color: #939393;
            border-bottom: 1px solid #EEEEEE;


            &:after {
                position: absolute;
                display: flex;
                top: 0;
                right: 0.5rem;
                width: auto;
                aspect-ratio: 1 / 1;
                height: 100%;
                max-width: 40px;
                justify-content: center;
                align-items: center;
                content: "\f061";
                font-family: "Font Awesome 5 Free";
                font-size: 1.1rem;
                font-weight: bold;
                color: #1B3A6C;

            }
        }
    }
}
