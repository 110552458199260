body{
    font-family: 'Poppins', sans-serif;
    height:100%;
}

html {
    scroll-behavior: smooth !important;
    height:100%;
}

#main {
    display:flex;
    flex-direction:column;
    min-height:100vh;
    justify-content: space-between;
}

.container-fluid {
    max-width:1980px;
}

@media (min-width: 768px) {

    .view-height{
        height: calc(100% - 113px);
    }

}

.navcontainer {
    padding:($spacer * 1.5) ($spacer * 3);

    @include media-breakpoint-down(md) {
        padding: ($spacer * 1) 0 !important;
    }

    .try-button:hover, .contact-button:hover {
        text-decoration:none;
        color:white;
    }

}

.section-content {

    padding: ($spacer * 3);
    overflow:hidden;
    background-color: white;

    .centered-sm-title {
        @include media-breakpoint-down(md) {
            text-align:center;
        }
    }

    .right {
        overflow:visible;
    }

    h1,h2 {
        background: linear-gradient(to right, $iss-light-blue, $iss-light-green);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 900;
        font-size: 2.7rem;
        margin-bottom:30px;
        line-height:3.4rem;

        @include media-breakpoint-down(lg) {
            font-size:2rem;
            line-height: 2.5rem;
        }

        @include media-breakpoint-down(md) {
            font-size:1.5rem;
            line-height: 2rem;
        }

        @include media-breakpoint-down(sm) {
            font-size:2rem;
            line-height: 2.5rem;
        }

    }

    h2 {
        @include media-breakpoint-down(md) {
            text-align:center;
        }
    }

    h3,h4 {
        font-weight: 900;
        font-size: 2rem;
        margin-bottom:30px;
        line-height:2.5rem;

        @include media-breakpoint-down(lg) {
            font-size:1.5rem;
            line-height: 2rem;
        }

        @include media-breakpoint-down(md) {
            font-size:1.2rem;
            line-height: 1.5rem;
        }

    }

    .multiline-title {

        @include media-breakpoint-up(md) {
            display: flex;
            flex-direction: column;
        }
    }

    .multiline-text {
        @include media-breakpoint-up(lg) {
            display: flex;
            flex-direction: column;
        }
    }
    

    .ante-cta-motto {
        font-size:1.2rem;
        font-weight:500;
    }
     
    
    .card-offer {
        border:1px solid #CFEFF9;
        background-color:#F0F8FA;
        
        p {
            font-size:24px;
            font-weight:500;
        }

        a {
            color:#10AEDF;
            background-color:#CFEFF9;
            text-decoration:none;
            border-radius:10px;
            padding:5px 10px;
            font-size:24px;
        }

        border-radius:20px;
        padding:50px 20px;

    }


    p, li {
        font-size: 1rem;
        margin-bottom:30px;

        @include media-breakpoint-down(lg) {
            font-size: 1rem !important;
        }

        @include media-breakpoint-down(md) {
            font-size: 0.9rem !important;
        }

        a {
            text-decoration:underline;
            color:#212529;
        }

    }

    .andmore {
        color: #13AFE1;
    }

    strong {
        font-weight: 600 !important;
    }

    @include media-breakpoint-down(md) {
        padding: ($spacer * 1);
    }

    ul {
        margin-bottom:40px;

        li {
            margin-bottom:2px;
            a {
                text-decoration:underline;
                color:#212529;
            }
    
        }
    }


}

.section-content.bg-alternate {
    background-color: #f0f8fa;

    .card-offer {
        background-color:white;
    }
}

.section-content.fullwidthsection {
    h1,h2 {
        margin-bottom:15px;
    }
    p,ul {
        font-size: 1rem !important;
    }
}

.popover {
    .popover-header {
        background-color:white;
    }

    .popover-body {
        display:none;
    }
}


.multilevel-image-container {

    //height:100%;
    height:auto;
    position:relative;
    margin:0;

    .phoneframed {
        position:relative;
        min-height:400px;

        img {
            position:absolute !important;
            filter: drop-shadow(0px 2px 2px #828282);
            top:0;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
        }
    }

    img {
        max-height:500px;
        position:relative;
        top:0;
        left:0;
        z-index:1;
    }


    .tooltips {
        position:absolute;
        z-index:2;
        background: linear-gradient(45deg, #14aae1 0%, #754ee0 60%, #9242de 100%);
        color:white;
        padding:5px 10px;
        font-size:0.7rem;
        white-space:nowrap;
        -webkit-filter: drop-shadow(0px 2px 2px rgba(130,130,130,1));
        filter        : drop-shadow(0px 2px 2px rgba(130,130,130,1));
        -ms-filter    : "progid:DXImageTransform.Microsoft.Dropshadow(OffX=0, OffY=2, Color='#444')";
        filter        : "progid:DXImageTransform.Microsoft.Dropshadow(OffX=0, OffY=2, Color='#444')";
    }

}

.form-submit-button {
    width: 100%;
    background: linear-gradient(to right, #0EAEDF, #67BC45);
    color: #fff !important;
    font-size: 1.3rem;
    font-weight: 500;
}

.form-submit-button:hover {
    background-color: black !important;
    opacity: 1;
    border-color: #000000 !important;
}

.text-underlined {
    text-decoration:underline;
}

.checkbox-medium {
    input {
        width: 24px;
        height: 24px;
    }

    label a {
        text-decoration: underline;
        color: inherit !important
    }
    
}


@media (min-width: 768px) and (max-width: 1366px) {

    .section-content.one-screen {
        height: calc(100% - 205px);
        overflow:visible;
    }

}

@media (min-width: 1366px) {

    .section-content.one-screen {
        height: calc(100% - 220px);
        overflow:visible;
    }

}


@media screen and ( max-height: 750px ){
    .section-content.one-screen {
        height: auto;
        overflow:visible;
    }
}

.inpageAnchor {
    scroll-margin-top:130px;
}
