.features {

    display:flex;
    flex-wrap: wrap;

    @include media-breakpoint-up(sm) {
        grid-template-columns: repeat(2, 1fr);
    }

    @include media-breakpoint-up(lg) {
        grid-template-columns: repeat(4, 1fr);
    }

    div {

        width:calc(33% - 40px);
        margin:20px;
        background-color:white;
        padding:30px;
        box-shadow: 4px 4px 17px -4px #0000002B;

        @include media-breakpoint-down(md) {
            width:100%;
        }
    
        @include media-breakpoint-down(sm) {
            width:50%;
        }

        @include media-breakpoint-down(xs) {
            width:100%;
        }

        h4 {
            color:#15AFDD;
            font-weight: 700;    
            font-size: 1.25rem;
            line-height: 1.5rem;
            display:flex;
            margin-bottom:20px;

            @include media-breakpoint-down(md) {
                font-size: 1rem;
                line-height: 1.2rem;
                margin-bottom:5px;
                font-weight: 600;    
            }

        }

    
    
        p {
            font-size:1rem;
            margin:0;

            @include media-breakpoint-down(lg) {
                font-size: 0.85rem !important;
            }
    
            @include media-breakpoint-down(md) {
                font-size: 0.75rem !important;
            }

            @include media-breakpoint-down(sm) {
            }
        }

    }

   
}


.features-animation {
    display:block;
    width:100%;
    margin-bottom:30px;
    border:1px dashed $iss-light-blue;
    padding:20px;
    border-radius:20px;
}

.features.full-width-features {
    div {
        width:33%;

        @include media-breakpoint-down(sm) {
            width:50%;
        }

        @include media-breakpoint-down(xs) {
            width:100%;
        }


    }
}

.features-highlights {
    
    display:inline-flex;
    flex-wrap:wrap;
    justify-content: center;

    @include media-breakpoint-down(lg) {
        font-size: 0.85rem !important;
    }

    @include media-breakpoint-down(md) {
        font-size: 0.75rem !important;
    }

    @include media-breakpoint-down(sm) {
        margin-left:auto;
    }
    
    div {

        width:calc(20% - 20px);
        padding:20px;
        box-shadow: 4px 4px 17px -4px #0000002B;
        margin:10px;

        @include media-breakpoint-down(lg) {
            width:calc(33% - 20px);
            padding:10px;
        }

        @include media-breakpoint-down(md) {
            width:calc(33% - 20px);
        }

        @include media-breakpoint-down(xs) {
            width:100%;
        }

        p {
            margin-bottom:0;
        }

        h2 {
            font-size:1.4rem;
            line-height:1.8rem;
            text-align:left !important;
            display:inline-block;
            margin-bottom:10px;
            width:200px;
    
            @include media-breakpoint-down(lg) {
                font-size: 1.2rem;
                line-height:1.5rem;
                width:auto;
            }

            @include media-breakpoint-down(md) {
                font-size: 1rem;
                line-height:initial;
                min-height:initial;
                margin-bottom:10px;
            }
            
        }

    }


}


.sample-grid {

    display:grid;
    grid-template-columns:1fr 1fr 1fr 1fr;
    grid-template-rows:1fr 1fr;


    div {
        padding:5px;
        overflow:hidden;

        img {
            height:100%;
            width:auto;
        }
    }

}


.catalogue-embeded {

    iframe {

        @include media-breakpoint-down(sm) {
            height:577px !important;
        }
    
    }

}