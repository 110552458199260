// Body
$body-bg: #fff;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 1rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #f05362;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$lightgray:hsl(146, 24%, 80%);;

// Custom
$link-color: #1e396b;
$button-color: #0eaedf;

$fa-font-path:        "/webfonts";

//custom-colors
$iss-light-blue: #13AFE1;
$iss-dark-blue: #1d3a6b;
$iss-light-green: #67BC45 ;
$iss-light-gray: #939393 ;
$iss-dark-gray: #333333 ;
//
$hiw_blue : #40B0E0; // #10AEDF
$hiw_teal : #50B4A7;
$hiw_green : #64B962;
$hiw_light_blue : #DCF0FB;

$container-max-widths: (
        xl: 1400px
) !default;
